@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circe';
  src: url('../assets/fonts/Circe/Circe-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

