html {
  font-family: Circe, sans-serif;
  font-weight: normal;
}

body {
  overflow-x: hidden;
}

#root {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
}
